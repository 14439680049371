$primary-color:  #53B99C;
$disabled-color: #DDDDDD;
$normal-color: #F3EFE4;
$background-color: #FDFDFD;
$text-color: #515151;
$icon-color: #151515;
$black-color: #000000;
$white-color: #ffffff;
$seperator-color: 	#D8D8D8;
$price-span: #417505;
$progress-color: #53B99C;
$movesize-bgcolor: #F3F3F3;
$bedsize-bgcolor:  #FDFDFD;

$main-font-family: 'Roboto', sans-serif;
$secondary-font-family:  'Heebo', sans-serif;
$dinpro-font-family:  'DINPro';

$kpi-grey: #666666;
$kpi-bg-color: #1A1819;

$f-01: 0.01rem;
$f-02: 0.02rem;
$f-03: 0.03rem;
$f-04: 0.04rem;
$f-05: 0.05rem;
$f-06: 0.06rem;
$f-07: 0.07rem;
$f-08: 0.08rem;
$f-09: 0.09rem;
$f-1: 0.1rem;
$f-2: 0.2rem;
$f-3: 0.3rem;
$f-4: 0.4rem;
$f-5: 0.5rem;
$f-6: 0.6rem;
$f-7: 0.7rem;
$f-8: 0.8rem;
$f-9: 0.9rem;
$f-10: 1rem;  // 10px
$f-11: 1.1rem; // 11px
$f-12: 1.2rem;
$f-13: 1.3rem;
$f-14: 1.4rem;
$f-15: 1.5rem;
$f-16: 1.6rem;
$f-17: 1.7rem;
$f-18: 1.8rem;
$f-19: 1.9rem;
$f-20: 2.0rem;
$f-21: 2.1rem;
$f-22: 2.2rem;
$f-23: 2.3rem;
$f-24: 2.4rem;
$f-25: 2.5rem;

$thin: 100;
$ultra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;
$black: 900;

$uppercase: uppercase;

$break-small: 320px;
$break-medium: 375px;
$break-large: 414px;
$break-minipad: 768px;
$break-maxipad: 1024px;
$break-desktop: 1200px;



$white-color: #ffffff;
$color-gray-bg: #F3F3F3;
$color-gray-light: #FAFAFA;
$color-tan-bg: #F3EFE4;
$primary: #00945D;
$color-green-neon: #7ED321;
$color-green-dark: #417505;
$color-green-progress: #53B99C;
$color-links:#000;
$yellow: #f8cb35;
$blue: #2A9FD8;
$active-bg:rgba(0,0,0,0.1);
$affirm-blue: #0FA0EA;

//navigation variables
$navigation-bg-color: #16644e;
$navigation-active-bg: #53b99c;

//button variables
$btn-primary-bg: $primary;
$btn-secondary-bg: #233d4d;
$btn-primary-color: $white-color;
$btn-hover-color: $color-green-progress;
$btn-focus-color: $color-green-progress;
$btn-active-color: $color-green-progress;

//price variables
$circle-bg: #d8d8d8;
$price-address-bg: #eaeaea;

//border variables
$border-grey-color: grey;
$border-lt-grey-color: #e0e0e0;

//padding variables
$padding-large: 30px;
$padding-medium: 20px;
$padding-small: 10px;
$padding-extra-small: 5px;

$btn-border-radius: 99999px;

$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-title: 'Heebo', sans-serif;

//text style variables
$text-transform: uppercase;



// list group variables

$border-radius: 0px;
$list-group-item-padding-y: 0.2em;
$list-group-item-padding-x: 0.75em;

// height variables
$navbar-logo-height: 3.35rem;

$progress-height: .5rem;

//Misc. variables

$ease-bounce: cubic-bezier(.75, .1, .1, 1.25);
$box-shadow: 0px 1px 3px rgba(10,10,10,0.2), 0px 1px 5px rgba(10,10,10,0.1);


@import './utils';
