@import '../../../sass/global/variables';

$kpi-grey: #666666;

:root {
    --rowCount: 6;
}

.kpi-table {

  color: $white-color;
  margin: 0.5rem;
  padding: 1.2em;


  &_title {
    font-weight: bold;
    width: 74%;

    h2{
      background: $kpi-bg-color;
      font-size: 2.4em;
      white-space: nowrap;
      font-weight: bold;
      font-family: $roboto;
    }
  }

  &_heading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $kpi-bg-color;
    padding: 0.3rem 3px 3px;
  }

  .kpi-label{
    font-size: 1.8em;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-family: $roboto;

  }



}



[data-column="yoy"]:not([data-row="heading"]),[data-column="target"]:not([data-row="heading"]) {

  padding: 0 4vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;


  span {
    text-align: right;
    font-size: calc(2rem + 1vmin);

    &:after {
    content: "%";

  }
}



}



[data-row="6"] { //make dynamic
  //padding-bottom: 1em;
}

[data-column]:not([data-column="label"]), .column-border {
  // max-width: 15rem;
  max-width: 12rem;
}


//==========================//
//=====Table Decorations====//
//=========================//


.racing-stripe {
  box-shadow: inset -5px 5px 1px $kpi-grey;
  border-radius: 0 20px 0 0;
  margin-top: 1rem;
}

.column-border {
border: 2px solid $kpi-grey;
border-radius: 3px;
box-shadow:
  0px 0px 0 3px $kpi-bg-color,
  0px 0px 0 3px $kpi-bg-color;
// max-width: 15rem;
max-width: 12rem;
}


//============================//
//====Table Cell Structure====//
//=== No styling goes here ===//
//============================//

[data-table="kpi"] {
display: grid;
grid-template-columns: auto auto auto 3%;
//grid-template-rows: repeat(var(--rowCount), auto);
//grid-template-rows: auto 1fr 1fr 1fr 1fr auto;
grid-column-gap: 1em;
grid-row-gap: 10px;
grid-template-areas:
    "head-label head-yoy head-target spacer"
    "row1-label row1-yoy row1-target spacer"
    "row2-label row2-yoy row2-target spacer"
    "row3-label row3-yoy row3-target spacer"
    "row4-label row4-yoy row4-target spacer"
    "row5-label row5-yoy row5-target spacer"
    "row6-label row6-yoy row6-target spacer";

//Shorthand property for grid-row-start grid-column-start grid-row-end and grid-column-end.

  .racing-stripe {
    grid-area: 1 / 1 / 7 / 5;
  }

  .column-border.yoy {
    grid-area: 1 / 2 / 8 / 3;
  }

  .column-border.target {
     grid-area: 1 / 3 / 8 / 4;
  }

}


[data-row="heading"] {

  &[data-column="label"] {
    grid-area: head-label; // 1 / 1 / 2 / 2;
  }

  &[data-column="yoy"] {
    grid-area: head-yoy; // 1 / 2 / 2 / 3;
  }

  &[data-column="target"] {
    grid-area: head-target; // 1 / 3 / 2 / 4;
  }

}


[data-row="1"] {

  &[data-column="label"] {
    grid-area: row1-label; // 2 / 1 / 3 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row1-yoy; // 2 / 2 / 3 / 3;
  }

  &[data-column="target"] {
    grid-area: row1-target; // 2 / 3 / 3 / 4;
  }

}

[data-row="2"] {

  &[data-column="label"] {
    grid-area: row2-label; // 3 / 1 / 4 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row2-yoy; // 3 / 2 / 4 / 3;
  }

  &[data-column="target"] {
    grid-area: row2-target; // 3 / 3 / 4 / 4;
  }

}

[data-row="3"] {

  &[data-column="label"] {
    grid-area: row3-label; // 4 / 1 / 5 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row3-yoy; // 4 / 2 / 5 / 3;
  }

  &[data-column="target"] {
    grid-area: row3-target; // 4 / 3 / 5 / 4;
  }

}

[data-row="4"] {

  &[data-column="label"] {
    grid-area: row4-label; // 5 / 1 / 6 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row4-yoy; // 5 / 2 / 6 / 3;
  }

  &[data-column="target"] {
    grid-area: row4-target; // 5 / 3 / 6 / 4;
  }

}

[data-row="5"] {

  &[data-column="label"] {
    grid-area: row5-label; // 6 / 1 / 7 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row5-yoy; // 6 / 2 / 7 / 3;
  }

  &[data-column="target"] {
    grid-area: row5-target; // 6 / 3 / 7 / 4;
  }

}

[data-row="6"] {

  &[data-column="label"] {
    grid-area: row6-label; // 6 / 1 / 7 / 2;
  }

  &[data-column="yoy"] {
    grid-area: row6-yoy; // 6 / 2 / 7 / 3;
  }

  &[data-column="target"] {
    grid-area: row6-target; // 6 / 3 / 7 / 4;
  }

}

//===========================//



@media (min-width: 1024px) {
    [data-column="yoy"]:not([data-row="heading"]),[data-column="target"]:not([data-row="heading"]) {

        font-size: 1.5em !important;
        //padding: 0 5vmin !important;
    }
    .kpi-label{
        font-size: 1.7em !important;
      }
}

@media (min-width: 350px){
.kpi-table .kpi-label{
  white-space: nowrap !important;
}
}
