.form-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	margin: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	bottom: 0;
}

.overlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(20px) saturate(160%);
	-webkit-backdrop-filter: blur(20px) saturate(160%);
}

article {
	position: relative;
	box-shadow: none;
	background: #f3f3f3;
	width: 100%;
	box-shadow: 0 0px 16px rgba(0, 0, 0, 0.25);
}

article>div {
	display: block;
}

.enter,
.exit {
	position: absolute;
	pointer-events: none;
}

.enter-done article:not(.drag) {
	min-height: 100vh;
	height: auto !important;
}

.enter article,
.appear article,
article.drag {
	overflow: hidden;
}

.enter .close,
.exit .close,
article.drag .close {
	position: absolute;
	opacity: 0;
}

.enter-active .close {
	opacity: 0.7;
}

article figure {
	margin: 0;
	width: 100%;
	flex-shrink: 0;
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 10;
}

article p {
	position: relative;
	padding: 20px 20px 40px;
	// padding:0px;
	margin: 0;
}

.close {
	position: fixed;
	top: 20px;
	z-index: 1000;
	width: 36px;
	height: 36px;
	border-radius: 18px;
	background: #fff;
	opacity: 0.7;
	cursor: pointer;
	transition: opacity 350ms ease;
}

.close::after,
.close::before {
	content: '';
	position: absolute;
	top: 17px;
	left: 8px;
	width: 20px;
	height: 3px;
	border-radius: 1.5px;
	background: #000;
	transform: rotateZ(45deg);
}

.close::before {
	transform: rotateZ(-45deg);
}

.close.invert {
	background: #000;
}

.close.invert::after,
.close.invert::before {
	background: #fff;
}