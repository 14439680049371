@import '../../sass/global/variables';

#leaderboard-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-color: #000;
    border-radius: 16px;
    margin: 1em;

    .leaderboard-detail{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .leader-style{
            background-color: #727272;
            overflow: hidden;
            color: white;
            .lead-country{
            padding-left: 0.9em;
            width: 9em;
            }
            .lead-score{
            margin-right: 0.5em;
            width: 2em;
            }
            .checker-flag{
            background-color: white;
            background: conic-gradient(#fff 0.25turn,
            #000 0.25turn 0.5turn, #fff 0.5turn 0.75turn,
             #000 0.75turn) top left / 25% 25% repeat;
            width: 2em;
            height: 1.315em;
            background-size: .667em .667em;
            }
            .color-obj{
                background: rgba(100,100,100,.8);
                width: 3.5em;
                height: 1.5em;
                transform: skew(-30deg);
                margin: 0 0 0 -1.9em;
                border-right: .25em solid black;
            }
            .lead-heading{
                margin: auto;
                font-size: 0.9em;
            }
        }
        ul{
            width: 85%;
            margin: 2em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            li{
                border: 1px solid #fff;
                font-size: 2.5em;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: $roboto;
                font-weight: bold;
                span{
                    font-size: 0.9em;
                    z-index: 1000;
                    padding-left: 0.5em;
                }
            }
        }
    }

}
@media (min-width: 1024px) {

    .leaderboard-detail{
        .color-obj{
            margin: 0 0 0 -11.2em !important;
        }

        ul{
            width: 74% !important;
            li{
                font-size: 3em !important;
                font-weight: normal !important;
                span{
                    font-size: 0.8em !important; 
                }
                .lead-country{
                    padding-left: -0.5em !important;
                                    
                }
                .lead-heading{
                   
                }

}
        }
    }
}
