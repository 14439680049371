@import '../../sass/global/variables';

.kpiprogress-container{ //removed id because it generates div containers with the same in ID in the DOM.
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: relative;
    width: 80%;
    margin: 5px auto;
    top: -13em;

    .kpi-progress-active{
        transform-origin: top right;
        height: 55px;
    }
    .kpi-progress-remaining{
        width: 100%;
        height: 55px;
      }
    .label-left{
        position: absolute;
        height: 30px;
        border-radius: 5px;
        background-color: gray;
        z-index: 1000;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.8em;
        font-weight: normal;
        top: 13px;
    }
    .left-value{
        width: auto;
        text-align: center;
        padding: 0.5em;
    }
    .right-value{
        width: auto;
        text-align: center;
        padding: 0.5em;
    }
    .label-right{
        position: absolute;
        height: 30px;
        border-radius: 5px;
        background-color: gray;
        z-index: 1000;
        right: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.8em;
        font-weight: normal;
        top: 13px;
    }
    .active-skew{
        position: absolute;
        z-index: 1000;
        height: 55px;
        top: 0;
        left: -0.2em;

    }

}
  
  .thisyear::before{
        content:"";
        background:linear-gradient(90deg,#FFF 50%,#000 0)0/5em,linear-gradient(#FFF 50%,#000 0)0/9% 5em;
        background-blend-mode:exclusion;
        width: 1.5em;
        height: 5.5em;
        background-size: 1.3em 1.3em;
        position: absolute;
        z-index: 1000;
        right: -14px;
    }
    .lastyear::before{
      content:"";
        background:linear-gradient(90deg,#474646 50%,#767475 0)0/5em,
        linear-gradient(#474646 50%,#767475 0)0/9% 5em;     
        background-blend-mode: difference;
        width: 1.5em;
        height: 5.5em;
        background-size: 1.3em 1.3em;
        position: absolute;
        z-index: 1000;
        right: -14px;
  }
  
@media (min-width: 1024px) {
    .kpiprogress-container{
        width: 90%;
    }

}
