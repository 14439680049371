/*----

This is a file for global base styles

These styles should be imported first so they can be overidden by themed styles or component-specific styles

-----*/

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}


  html, body {
    margin: 0;
    padding: 0;
    //position: 'fixed';
  }

  body {

    line-height: 1.6;
    font-family: 'Roboto' , 'DINPro', 'Heebo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    position: relative;
    width: 100%;
  }
