html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100%;
      font-family: $base-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  body {
 /*    background: url("https://images.unsplash.com/photo-1574785992306-2472ade8a7a0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"), linear-gradient(180deg, rgba(26, 24, 25, 1) 0%, #1A1819 100%);
    background-size:cover;
    background-position:center bottom;
    background-blend-mode:overlay;
     background-repeat: no-repeat;
    background-attachment: fixed; */
  }
  
  
  
  
  