@import 'src/theme/styles/variables';

/* inner content*/

.main-content {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 75px;
}

.move-heading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.move-heading h1 {
	font-size: calc(#{7rem} + 2vmin);
	font-weight: bold;
	text-transform: uppercase;
}

.move-heading h2 {
	font-size: calc(#{4.2rem} + 2vmin);
	font-weight: 600;
	text-transform: uppercase;
	line-height: 15px;
}

.move-affordable {
	@include debug;
	padding-top: 20px;
}

.move-affordable h3 {
	font-size: calc(#{4rem} + 2vmin);
	color: #309A66;
	text-align: center;
}

@media screen and (max-width: 414px) {
	.main-content {
		padding-top: 15px;
	}
}

@media screen and (min-width: 641px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}