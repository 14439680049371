@import './styles/variables.scss';



html, #root{
background: $kpi-bg-color;
background: url("https://images.unsplash.com/photo-1574785992306-2472ade8a7a0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"), linear-gradient(180deg, rgba(26, 24, 25, 1) 0%, #1A1819 100%);
background-size:cover;
background-position:center bottom;
background-blend-mode:overlay;
background-repeat: no-repeat;
background-attachment: fixed;
height: 100%;
margin: 0;
}
