@import '../../../sass/global/variables';


.kpi-arrow {
  font-size: 1.3rem;

  &.up:before {
    content:"\25B2";
  }

  &.down:before {
    content:"\25BC";
  }

  &.neutral:before {
    content:"x";
  }

}
