@import 'src/theme/styles/variables';
.movesize-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

%bedsize-style {
	display: flex;
	height: 50px;
	border: 1px solid rgba(216, 216, 216, 0.72);
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	font-family: $main-font-family;
}

%sizelist-style {
	list-style: none;
	color: #000000;
	font-size: calc(#{$f-12} + 2vmin);
	text-transform: uppercase;
	font-family: $main-font-family;
}

.slick-slide {}

.slick-slide img {}

.select-list {
	@extend %bedsize-style;
	height: 90px;
	background-color: #FDFDFD;
	flex: 1 1 auto;
	background-position: center;
	transition: background 0.3s;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-family: $main-font-family;
	.list-icon{
		@extend %sizelist-style;
		margin: 0;
		padding-left: 20px;
		width: 15%;
	}
	.list-text {
		@extend %sizelist-style;
		margin: 0;
		padding-left: 28px;
		width: 77%;
	}
	span {
		font-family: $main-font-family;
		font-weight: $regular;
	}
}

.select-list:hover {
	background: darken(#FDFDFD, 1%) !important;
	cursor: pointer;
}

.select-list:active {
	background-color: $movesize-bgcolor;
	background-size: 100%;
	transition: background 0s;
}

@media (min-width: 320px) {}

@media (min-width: 641px) {}

@media (min-width: 768px) {
	.select-list {
		justify-content: flex-start !important;
	}
	.list-icon {
		width: 3% !important;
	}
	.list-text {
		padding-left: 73px !important;
	}
}

@media (min-width: 1024px) {
	.list-icon {
		width: 3% !important;
		padding-left: 34px !important;
	}
	.list-text {
		padding-left: 67px !important;
		font-size: calc(#{$f-8} + 2vmin) !important;
		font-weight: $semibold;
	}
}
